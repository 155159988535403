import { useState } from 'react';

import { Button, ListGroup } from 'react-bootstrap';
import { Github } from 'react-bootstrap-icons';

const CodingChallenges = () => {
    const [activeTab, setActiveTab] = useState('FakerAPI');

    const connect4JSX = () => {
        if(activeTab === 'FakerAPI') {
            return <div className='px-3 py-2'>
            <h5 className='fw-bold mb-3 text-end'>
                <u>FakerAPI UX Challenge</u>
            </h5>
            <p className='mb-5 text-end'>
                This was a coding challenge that I did for a Senior Frontend Developer position at ChangeIs.
                Its a small web app that calls FakerAPI with a datatype query parameter, and displays the response.
                Written from scratch in a morning <a className='fw-bold text-info' href='https://www.typescriptlang.org/' target='_blank'>Typescript</a>
                <span> & </span>
                <a className='fw-bold text-info' href='https://react.dev' target='_blank'>ReactJS</a>.
            </p>
            <p className='align-items-center d-flex gap-3 justify-content-end'>
                <a href='https://github.com/rolandforbes/viteFaker/tree/main/changeis' style={{ fontSize: '16px' }} target='_blank'>
                    <Github className='text-info' />
                </a>
                <Button href='https://changeiscoding.web.app' target='_blank' variant='outline-warning'>
                    View Demo
                </Button>
            </p>
        </div>
        }
        return <div className='px-3 py-2'>
            <h5 className='fw-bold mb-3 text-end'>
                <u>Connect4 Board Game</u>
            </h5>
            <p className='mb-5 text-end'>
                This was a coding challenge that I did for a Staff Frontend Software Engineering position at Esusu.
                It's a based off a classic game called <a className='fw-bold text-success' href='https://en.wikipedia.org/wiki/Connect_Four' target='_blank'>Connect4,</a> where you drop coins onto a board, and you need 4 matching coins in a column or row to.
                Written from scratch in a couple days using <a className='fw-bold text-info' href='https://www.typescriptlang.org/' target='_blank'>Typescript</a>
                <span> & </span>
                <a className='fw-bold text-primary' href='https://react.dev' target='_blank'>ReactJS</a>.
            </p>
            <p className='text-end'>
                <b className='pe-1'>
                    <a className='text-warning' href='https://gitlab.com/rolandforbes/esusu-coding-challenge' target='_blank'>
                        <span className='fw-bold pe-2'>The Code in Gitlab</span>
                        <Github />
                    </a>
                </b>
            </p>
        </div>;
    }

    const nameJSX = () => {
        return <div className='align-items-center d-flex'>
            <h2>Coding Challenges!</h2>
            <div className='border-bottom flex-fill ms-3' />
        </div>;
    }

    const sidenavJSX = () => {
        return <div className='border-right'>
            <ListGroup variant='flush'>
                <ListGroup.Item className={activeTab === 'OnJumprun' ? 'fw-bold' : 'fw-light'} onClick={() => setActiveTab('OnJumprun')}>
                    Connect4
                </ListGroup.Item>
                <ListGroup.Item className={activeTab === 'FakerAPI' ? 'fw-bold' : 'fw-light'} onClick={() => setActiveTab('FakerAPI')}>
                    FakerAPI
                </ListGroup.Item>
            </ListGroup>
        </div>;
    }

    return <div className='align-items-center d-flex justify-content-center'>
        <div className='centered-wide'>
            {nameJSX()}
            <div className='d-flex gap-4 pt-3'>
                {sidenavJSX()}
                {connect4JSX()}
            </div>
        </div>
    </div>;
}
export default CodingChallenges;