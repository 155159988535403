import { useState } from "react";

const OrangeLady = () => {
    return <div className='fuckesusu' style={{ position: 'fixed', bottom: '60px', left: '260px' }}>
        <div>
            <div className='d-flex px-3'>
                <div className='position-relative'>
                    <div className="white-left" />
                </div>
                <span className='orangejerection'>
                    <div className='orangeLady' />
                    <div className='black-bottom-left' />
                    <div className='orange1' />
                    <div className='orangepin' />
                    <div className='forearm' />
                    <div className='rabbit' />
                    <div className='f1' />
                    <div className='f2' />
                    <div className='f3' />
                    <div className='hand' />
                    <div className='face1' />
                    <div className='eye1'>
                        <div className='retina1' />
                    </div>
                    <div className='righteye1'>
                        <div className='retina1' />
                    </div>
                </span>
                <div className='position-relative'>
                    <div className="white-right" />
                </div>
            </div>
        </div>
        <div className='white-bottom' />
    </div>;
}
export default OrangeLady;