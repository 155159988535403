const Header = () => {
    return <div>
        <div className='align-items-center d-flex justify-content-between px-5 py-4'>
            <h1 className='cursor fw-bold rolo-header-logo shimmer' onClick={() => window.location.reload()}>
                <h2>RF</h2>
            </h1>
            <div className='rolo-header-navbar'>
                <img style={{ width: '24px' }} alt="USA" src="http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg" />
            </div>
        </div>
    </div>;
}
export default Header;