import { useEffect, useState } from 'react';

import { Spinner } from 'react-bootstrap';

import AboutMe from './AboutMe';
import CodingChallenges from './CodingChallenges';
import Contact from './Contact';
import Footer from './Footer';
import GreenLady from './GreenLady';
import Header from './Header';
import NavMenu from './NavMenu';
import SideProjects from './SideProjects';
import Work from './ProfessionalCareer';
import OrangeLady from './OrangeLady';

const HomePage = () => {
    const [view, setView] = useState('about');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => setIsLoading(false), 2999);
    }, []);

    const mainContent = () => {
        switch(view) {
            case 'coding challenges': return <CodingChallenges />;
            // case 'work': return <Work />;
            case 'side projects': return <SideProjects />;
            case 'contact': return <Contact />;
            default: return <AboutMe />;
        }
    }

    return <>
        {isLoading ? <div className='vh-100-actual bg-dark' style={{ overflow: isLoading ? 'hidden' : 'inherit' }}>
            <div className='delay-animation-short fixed-center text-center'>
                <h2 className='fw-bold mb-3'>Digging for fire..</h2>
                <Spinner animation='border' className='opacity-75' size='sm' variant='warning' />
            </div>
        </div> : <div className='bg-dark position-relative'>
            <Header />
            <div className='main-content'>
                {mainContent()}
            </div>
            <section className='bg-dark blue'>
                <div className='curve' />
            </section>
            <GreenLady />
            <OrangeLady />
            <NavMenu setView={setView} view={view} />
        </div>}
    </>;
}
export default HomePage;