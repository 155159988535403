

const NavMenu = ({ setView, view }: any) => {

    return <div className='cursor d-none d-md-block' style={{ position: 'fixed', bottom: '80px', right: '2vw' }}>
        {/* <p className={['fw-bold mb-0 text-black text-end', view == 'about' ? 'underline' : ''].join(' ')}
            onClick={() => setView('about')}>ABOUT -</p> */}
        <p className={['fw-bold mb-0 text-black text-end', view == 'work' ? 'underline' : ''].join(' ')}
            onClick={() => setView('work')}>PROFESSIONAL WORK -</p>
        <p className={['fw-bold mb-0 text-black text-end', view == 'side projects' ? 'underline' : ''].join(' ')}
            onClick={() => setView('side projects')}>SIDE PROJECTS -</p>
        <p className={['fw-bold mb-0 text-black text-end', view == 'coding challenges' ? 'underline' : ''].join(' ')}
            onClick={() => setView('coding challenges')}>CODING CHALLENGES -</p>
        <p className={['fw-bold mb-0 text-black text-end', view == 'contact' ? 'underline' : ''].join(' ')}
            onClick={() => setView('contact')}>CONTACT -</p>
    </div>;
}
export default NavMenu;