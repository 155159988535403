
import { initializeApp } from 'firebase/app';
import 'bootstrap/dist/css/bootstrap.min.css';

import HomePage from './components/HomePage';
import './App.css';

initializeApp({
  apiKey: 'AIzaSyAzeqIxRTqa5KkCnSRKM9ujVmf_Mxr8hxU',
  appId: '1:175941949254:web:aa50696b76729ae9537747',
  authDomain: 'versuslaw-1fcce.firebaseapp.com',
  messagingSenderId: '175941949254',
  measurementId: 'G-LH3PCES7X5',
  projectId: 'versuslaw-1fcce',
  storageBucket: 'versuslaw-1fcce.appspot.com'
});

const App = () => {
  return <div className='rolo'>
      <HomePage />
  </div>;
}
export default App;