import { useEffect, useState } from 'react';

import { Badge } from 'react-bootstrap';

import me from '../images/testtest.jpg';

const AboutMe = () => {
    const [animationDone, setAnimationDone] = useState(false);

    useEffect(() => {
        setTimeout(() => setAnimationDone(true), 3069);
    }, []);

    const skillBadgesJSX = () => {
        return <div className='pt-2'>
            <div className='align-items-center d-flex mb-2 me-5'>
                <h3 className='fw-bold'>Technical Expertise In</h3>
                <div className='border-bottom flex-fill mx-3' />
            </div>
            <div className='d-flex gap-2 mb-2 overflow-scroll'>
                <Badge bg='dark' className='border-primary p-2 text-secondary' pill>Typescript</Badge>
                <Badge bg='dark' className='border-success p-2 text-secondary' pill>Javascript</Badge>
                <Badge bg='dark' className='border-info p-2 text-secondary' pill>HTML/CSS</Badge>
                <Badge bg='dark' className='border-warning p-2 text-secondary' pill>ReactJS</Badge>
                <Badge bg='dark' className='border-light p-2 text-secondary' pill>.NET Framework</Badge>
                <Badge bg='dark' className='border-secondary p-2 text-secondary' pill>MySQL</Badge>
            </div>
            <div className='d-flex gap-2 overflow-scroll'>
                <Badge bg='dark' className='border-info text-secondary p-2' pill>SQL Server Management Studio</Badge>
                <Badge bg='dark' className='border-secondary p-2 text-secondary' pill>Swift/iOS</Badge>
                <Badge bg='dark' className='border-success p-2 text-secondary' pill>Firebase</Badge>
                <Badge bg='dark' className='border-danger p-2 text-secondary' pill>Wordpress</Badge>
            </div>
        </div>;
    }

    return <div className='align-items-center d-flex gap-4 justify-content-center mobile-padding'>
        <div className='centered'>
            <div className='align-items-center d-flex justify-content-center'>
                <h3 className='mobile-center'>Websites, Mobile Apps, & UX Prototyping</h3>
                <div className='border-bottom flex-fill mx-3 d-none d-lg-block' />
            </div>
            <h6 className='pb-3'>
                <br />
                <span className='opacity-75'>
                    With over a decade of software development experience, I'm a Full Stack Web Engineer who specializes in helping enterprise level teams deliver SaaS at scale.<br /><br />
                    What does that even mean? Basically, I'm an artisan bread maker, just for websites and mobile apps. If you want to bake up something toasty and fresh, I'm your guy.
                    <br /><br />
                    Not accepting new projects.
                </span><br/>
                <small className='fw-light text-info'>Potentially swayed with pastries...</small>
            </h6>
            <div className='pe-3'>
                {skillBadgesJSX()}
            </div>
        </div>
        <div className='position-relative profile-wrap'>
            <div className={!animationDone ? '' : 'profile-photo-orange'} />
            <img className='cursor profile-photo' src={me} />
        </div>
    </div>;
}
export default AboutMe;