import { useState } from 'react';

import { ListGroup } from 'react-bootstrap';

const SideProjects = () => {
    const [activeTab, setActiveTab] = useState('OnJumprun');

    //-----Pure JSX Functions-----//

    const hucklberryJSX = () => {
        return <div className='px-3 py-2'>
            <h5 className='fw-bold mb-3 text-end'>
                <u>Full Stack Developer</u>
            </h5>
            <p className='mb-4 text-end'>
                This web app is an AirBnb clone. The only differences are that it charges signifcantly less than AirBnb would. Additionally,
                it has functionality for year long leases. This app is running a backend on Google Firebase, using FireStore NoSQL.
            </p>
            <p className='text-end'>
                <b className='pe-1'>
                    <a className='fw-bold text-warning' href='http://hucklberry.com' target='_blank'>Hucklberry Website</a>
                </b>
            </p>
        </div>;
    }

    const nameJSX = () => {
        return <div className='align-items-center d-flex'>
            <h2>Side Projects</h2>
            <div className='border-bottom flex-fill ms-3' />
        </div>;
    }

    const onJumprunJSX = () => {
        return <div className='px-3 py-2'>
            <h5 className='fw-bold mb-3 text-end'>
                <u>Full Stack Developer</u>
            </h5>
            <p className='mb-5 text-end'>
                OnJumprun is an an app for skydivers, by skydivers. Book customers, process payments, and run your entire dropzone OnJumprun.com.
            </p>
            <p className='text-end'>
                <b className='pe-1'>
                    <a className='fw-bold text-warning' href='http://onjumprun.com' target='_blank'>OnJumprun Website</a>
                </b>
            </p>
        </div>;
    }

    const oneHealthJSX = () => {
        return <div className='px-3 py-2'>
            <h5 className='fw-bold mb-3 text-end'>
                <u>HandlebarsJS Developer</u>
            </h5>
            <p className='mb-4 text-end'>
                I maintained an array of client lab reports, essentially styling JSON payloads in HandlebarsJS templating
                engine. This role was 10-15 hours a week, and purely HTML/CSS, and data interpretation/reverse engineering.
            </p>
            <p className='text-end'>
                <b className='pe-1'>
                    <a className='fw-bold text-warning' href='https://1health.io' target='_blank'>One Health Website</a>
                </b>
            </p>
        </div>;
    }

    const rightSideJSX = () => {
        switch (activeTab) {
            case 'One Health': return oneHealthJSX();
            case 'OnJumprun': return onJumprunJSX();
            case 'Versus Law': return versusJSX();
            default: return hucklberryJSX();
        }
    }

    const sidenavJSX = () => {
        return <div className='border-right'>
            <ListGroup variant='flush'>
                <ListGroup.Item className={activeTab === 'OnJumprun' ? 'fw-bold' : 'fw-light'} onClick={() => setActiveTab('OnJumprun')}>
                    OnJumprun
                </ListGroup.Item>
                <ListGroup.Item className={activeTab === 'One Health' ? 'fw-bold' : 'fw-light'} onClick={() => setActiveTab('One Health')}>
                    OneHealth.io
                </ListGroup.Item>
                <ListGroup.Item className={activeTab === 'Versus Law' ? 'fw-bold' : 'fw-light'} onClick={() => setActiveTab('Versus Law')}>
                    Versus Law
                </ListGroup.Item>
                <ListGroup.Item className={activeTab === 'Hucklberry' ? 'fw-bold' : 'fw-light'} onClick={() => setActiveTab('Hucklberry')}>
                    Hucklberry
                </ListGroup.Item>
            </ListGroup>
        </div>;
    }

    const versusJSX = () => {
        return <div className='px-3 py-2'>
            <h5 className='fw-bold mb-3 text-end'>
                <u>UX Designer/UI Developer</u>
            </h5>
            <p className='mb-4 text-end'>
                This was a contract role that entailed UI development and UX design. Versus is a law practice
                management application so that lawyers can manage their cases and clients. Its written in React & Typescript with a serverless backend
                (running on Firebase).
            </p>
            <p className='text-end'>
                <b className='pe-1'>
                    <a className='fw-bold text-warning' href='https://versuslaw-1fcce.web.app/' target='_blank'>Versus Law Website</a>
                </b>
            </p>
        </div>;
    }

    return <div className='align-items-center d-flex justify-content-center'>
        <div className='centered-wide'>
            {nameJSX()}
            <div className='d-flex gap-4 pt-3'>
                {sidenavJSX()}
                {rightSideJSX()}
            </div>
        </div>
    </div>;
}
export default SideProjects;