import { useState } from "react";
import { Button, Collapse } from "react-bootstrap";

const GreenLady = () => {

    return <div className='fuckesusu' style={{ position: 'fixed', bottom: '60px', left: '12px' }}>
        <div>
            <div className="d-flex px-3">
                <div className="position-relative">
                    <div className="white-left" />
                </div>
                <span className="jerection">
                    <div className="greenLady"></div>
                    <div className='black-bottom-right' />
                    <div className='green-lady-face'>
                        <div className='left-eye'>
                            <div className="retina" />
                        </div>
                        <div className='right-eye'>
                            <div className='retina' />
                        </div>
                    </div>
                </span>
                <div className="position-relative">
                    <div className="white-right" />
                </div>
            </div>
        </div>
        <div className='d-flex justify-content-between p-3 text-black white-bottom'>
            <p className='fw-bold mt-1' style={{ lineHeight: '10px'}}>
                CSS Nun<br/>
                <small className='text-danger' style={{ fontSize: '9px' }}>^ Artwork made from CSS only</small>
            </p>
        </div>
    </div>;
}
export default GreenLady;