
import { Badge } from 'react-bootstrap';

const Contact = () => {

    //-----Pure JSX Functions-----//

    return <div className='align-items-center d-flex justify-content-center' style={{ minHeight: '40vh' }}>
        <div>
            <div className='d-flex justify-content-center'>
                <Badge className='px-4 py-2' pill>
                    <h3 className='fw-bold mb-0 text-center'>
                        Text at your own risk
                    </h3>
                </Badge>
            </div>
            <h4 className='fw-bold mb-1 mt-4 opacity-50 text-center whiteOnHover'>// 406.426.1091</h4>
            <h4 className='fw-bold opacity-50 text-center whiteOnHover'>// rolandandrewforbes<b>@</b>gmail.com</h4>
        </div>
    </div>;
}
export default Contact;